<template>
  <div class="page-container">
    <div class="title-container">
      <Title :title="title" />
      <div></div>
    </div>
    <Loader :loading="isLoader" v-if="isLoader" />
    <div v-else class="container">
      <div class="flex justify-evenly">
        <div
          class="flex flex-col"
          v-for="(report, index) in reports"
          :key="index"
        >
          <div class="flex-none">
            <router-link :to="report.urlPath" class="report-thumb" exact>
              <div class="flex">
                <div class="flex items-center mr-10">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path
                      d="M1 10h3v10H1V10zM6 0h3v20H6V0zm5 8h3v12h-3V8zm5-4h3v16h-3V4z"
                    />
                  </svg>
                </div>
                <div>
                  <h3>{{ report.title }}</h3>
                  <p class="small">
                    {{ report.description }}
                  </p>
                </div>
              </div>

              <div class="go-corner" href="javascript:void(0)">
                <div class="go-arrow">→</div>
              </div>
            </router-link>
          </div>
        </div>      
      </div>
      <div class="flex-grow ml-4 sub-content__area">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    Title: () => import(/* webpackPrefetch: true */ "../common/Title"),
    Loader: () => import(/* webpackPrefetch: true */ "../common/Loader"),
  },
  data: () => ({
    title: "Reports",
    isLoader: true,
    size: "5px",
    reports: [
      {
        title: "Nominal Composition Report",
        urlPath: "/reports/nominalcompositionreport",
        description: " will provide holistic view of the data ",
      },
      {
        title: "Product Formulation Mapping Report",
        urlPath: "/reports/cucreport",
        description: " will provide holistic view of the data ",
      },
      {
        title: "Brand Product Mapping Report",
        urlPath: "/reports/productreport",
        description: " will provide holistic view of the data ",
      },
    ],
  }),
  created() {
    setTimeout(() => {
      this.isLoader = false;
      this.$router.push({ name: "nominalcompositionreport" }).catch(() => {});
    }, 500);
  },
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
h3 {
  color: #444;
  font-size: 1.3rem;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 4px;
}
svg {
  width: 80px;
  fill: #00838d;
}
p {
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 20px;
  color: #666666;
  &.small {
    font-size: 1.1rem;
  }
}

.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 22px;
  height: 22px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: #00838d;
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}

.report-thumb {
  display: block;
  position: relative;
  max-width: 620px;
  background-color: #effdff;
  border-radius: 4px;
  padding: 12px 15px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #00838d;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
  }
  &:hover:before {
    transform: scale(40);
  }
}

.report-thumb:hover,
.active:hover {
  p {
    transition: all 0.3s ease-out;
    color: rgba(255, 255, 255, 0.8);
  }
  h3 {
    transition: all 0.3s ease-out;
    color: #ffffff;
  }
  svg {
    transition: all 0.3s ease-out;
    fill: #fff;
  }
}
.router-link-exact-active.router-link-active {
  background-color: #00838d;
  p,
  h3 {
    color: #ffffff;
  }
  svg {
    fill: #fff;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
  will-change: opacity;
  transform: translateY(30%);
}

$color-primary: #4c4c4c;
$color-secondary: #a6a6a6;
$color-highlight: #ff3f40;
</style>
