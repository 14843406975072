var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "title-container" },
        [_c("Title", { attrs: { title: _vm.title } }), _c("div")],
        1
      ),
      _vm.isLoader
        ? _c("Loader", { attrs: { loading: _vm.isLoader } })
        : _c("div", { staticClass: "container" }, [
            _c(
              "div",
              { staticClass: "flex justify-evenly" },
              _vm._l(_vm.reports, function(report, index) {
                return _c("div", { key: index, staticClass: "flex flex-col" }, [
                  _c(
                    "div",
                    { staticClass: "flex-none" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "report-thumb",
                          attrs: { to: report.urlPath, exact: "" }
                        },
                        [
                          _c("div", { staticClass: "flex" }, [
                            _c(
                              "div",
                              { staticClass: "flex items-center mr-10" },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      viewBox: "0 0 20 20"
                                    }
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d:
                                          "M1 10h3v10H1V10zM6 0h3v20H6V0zm5 8h3v12h-3V8zm5-4h3v16h-3V4z"
                                      }
                                    })
                                  ]
                                )
                              ]
                            ),
                            _c("div", [
                              _c("h3", [_vm._v(_vm._s(report.title))]),
                              _c("p", { staticClass: "small" }, [
                                _vm._v(" " + _vm._s(report.description) + " ")
                              ])
                            ])
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "go-corner",
                              attrs: { href: "javascript:void(0)" }
                            },
                            [
                              _c("div", { staticClass: "go-arrow" }, [
                                _vm._v("→")
                              ])
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "flex-grow ml-4 sub-content__area" },
              [_c("router-view")],
              1
            )
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }